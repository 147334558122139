import { until } from '@vueuse/core'

interface ErrorLike {
  data?: any
  fatal?: boolean
  statusCode?: number
  message?: string
  status?: number
}

type AnyError = string | ErrorLike | null

/**
 * 處理致命錯誤：例如頁面初次載入資料失敗
 */
export function handleFatal(error?: string | any, defaultMessage?: string): void {
  handle(error, true, defaultMessage)
}

/**
 * 處理執行階段例外：例如操作時的失敗
 */
export function handleException(error?: string | any, defaultMessage?: string): void {
  handle(error, false, defaultMessage)
}

async function handle(error?: AnyError | Ref<AnyError>, fatal = false, defaultMessage?: string): Promise<void> {

  let unwrapError

  // unwrap reactive error
  if (isRef(error)) {
    await until(error).toBeTruthy()
    unwrapError = unref(error) as AnyError
    error.value = null // 錯誤已接收，清除原始的錯誤
  }

  // 呼叫了 HandleError，但沒傳入任何東西
  if (!unwrapError) {
    displayError(defaultMessage ?? 'Oops! something\'s wrong', 500, fatal)
    return
  }

  // 直接顯示自訂訊息
  if (typeof unwrapError === 'string') {
    displayError(unwrapError, 500, fatal)
    return
  }

  const { t } = useNuxtApp().$i18n
  const statusCode = unwrapError.status ?? unwrapError.statusCode
  const errorMessage = unwrapError.message

  // 特殊錯誤
  if ((errorMessage && (errorMessage?.indexOf('無效的登入權杖') >= 0 || errorMessage?.indexOf('登入權杖已過期') >= 0)) || statusCode === 401 || statusCode === 440) {
    // showError({ statusCode: 401, statusMessage: '無效的登入權杖' })
    useToast(t(Words.ErrorOnSessionExpired), { timeout: 5000 })
    useLogout({ eraseOnly: true })
    useLogin()
    return
  }

  // API 錯誤
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#server_error_responses
  if (statusCode) {
    // API 回應 500
    if (statusCode >= 500) {
      displayError(defaultMessage ?? t(Words.ErrorOnRead), 500, fatal)
      return
    }
  }

  // 其他不知道啥錯誤
  displayError(errorMessage ?? defaultMessage ?? 'Oops! something\'s wrong', 500, fatal)

}

function displayError(message: string, statusCode: number = 500, fatal = false): void {
  if (process.server || fatal) {
    showError({ statusCode, statusMessage: message })
  }
  if (process.client) {
    useToast(message, { timeout: 5000, color: 'error' })
  }
}